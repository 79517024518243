var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PublicLayout',[_c('div',{staticClass:"home",class:{
            xs: _vm.$vuetify.breakpoint.xsOnly,
            sm: _vm.$vuetify.breakpoint.smOnly,
        }},[_c('div',{staticClass:"home--title py-8"},[_c('h4',{staticClass:"text-md-h4 font-weight-black p-0 mb-3"},[_vm._v(" Best NFT collections ")]),_c('p',{staticClass:"text-md-body info--text"},[_vm._v(" Best and newest collections of NFT on sale "),_c('br'),_vm._v(" Grab yours now and HODL to it! WAGMI ")])]),_c('div',{staticClass:"home--sorting"},[_c('v-btn',{staticClass:"text-caption pa-0",attrs:{"text":"","href":"#mint"}},[_c('div',{staticClass:"button"},[_c('span',{staticClass:"secondary--text"},[_vm._v("Best NFT to be minted")]),_c('v-icon',{attrs:{"size":"16","color":"secondary"}},[_vm._v(" mdi-arrow-down-thin-circle-outline ")])],1)]),_c('v-btn',{staticClass:"text-caption pa-0",attrs:{"text":"","href":"#live"}},[_c('div',{staticClass:"button"},[_c('span',{staticClass:"secondary--text"},[_vm._v("Top NFT to buy now")]),_c('v-icon',{attrs:{"size":"16","color":"secondary"}},[_vm._v("mdi-arrow-down-thin-circle-outline")])],1)])],1),_c('div',{staticClass:"home--products"},[_c('div',{style:({
                    'grid-template-rows': ("repeat(" + (_vm.pag.total - _vm.pag.to > 0
                            ? _vm.pag.per_page
                            : _vm.pag.total - _vm.pag.from + 1) + ", 1fr)"),
                }),attrs:{"id":"mint"}},_vm._l((_vm.mintTokens),function(nftoken,i){return _c('ProductCard',{key:i,attrs:{"product":nftoken}})}),1),_c('div',{style:({
                    'grid-template-rows': ("repeat(" + (_vm.pag.total - _vm.pag.to > 0
                            ? _vm.pag.per_page
                            : _vm.pag.total - _vm.pag.from + 1) + ", 1fr)"),
                }),attrs:{"id":"live"}},_vm._l((_vm.liveTokens),function(nftoken,i){return _c('ProductCard',{key:i,attrs:{"product":nftoken}})}),1)]),(_vm.pag)?_c('div',{staticClass:"home--pagination"},[_c('v-btn',{attrs:{"href":_vm.$route.path + '?page=' + (_vm.pag.current_page - 1),"outlined":"","rounded":"","disabled":_vm.pag.current_page == 1,"color":"secondary"}},[_vm._v(" "+_vm._s(_vm.prevPage)+" ")]),_c('v-btn',{attrs:{"disabled":_vm.pag.current_page == _vm.pag.last_page,"href":_vm.$route.path + '?page=' + (_vm.pag.current_page + 1),"rounded":"","outlined":"","color":"secondary"}},[_vm._v(" "+_vm._s(_vm.nextPage)+" ")])],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }