import Vue from 'vue'
import './scss/app.scss';
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

// import VueAnalytics from 'vue-analytics';
import VueLazyload from 'vue-lazyload'
Vue.config.productionTip = false
require('./bootstrap');
import './plugins/vue-auth'
import 'axios-progress-bar/dist/nprogress.css'
import { loadProgressBar } from 'axios-progress-bar'
loadProgressBar()
Vue.use(VueLazyload, {
    preLoad: 1,
    attempt: 1,
    listenEvents: ['scroll'],
    observer: true,
    observerOptions: {
        rootMargin: '0px',
        threshold: 0.1
    }
});



// Vue.use(VueAnalytics, {
//     id: 'G-NWHH9BB593',
//     router
// })


const requireComponent = require.context("./components/global", true, /\.vue$/);
requireComponent.keys().forEach(filename => {
    const component = requireComponent(filename)
    const componentName = filename.split("/").pop().replace(/\.\w+$/, "").replace(/-([a-z])/g, g => g[1].toUpperCase()).replace(/(\b\w)/gi, m => m.toUpperCase());
    Vue.component(componentName, component.default || component); /* Global component registration */
});

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
